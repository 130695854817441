import React, { useEffect, useState } from "react";
import { navigateToUrl } from "single-spa";
import classNames from "classnames";
import BrowseMarketsButtonIcon from "./browse-markets-button.icon";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import styles from "../home-button/home-button.styles.module.scss";
import { TypographyComponent } from "gx-npm-ui";
import FadeContainerComponent from "../../ui/fade-container.component";

type BrowseMarketsButtonComponentProps = {
  isExpanded?: boolean;
};

const BrowseMarketsButtonComponent = ({ isExpanded = false }: BrowseMarketsButtonComponentProps) => {
  const { t } = useTranslation();
  const [isSelected, setIsSelected] = useState(false);
  const location = useLocation();
  const buttonUrlPath = "/s/markets";
  const tabLabel = t("Browse markets");
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement> | React.KeyboardEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    navigateToUrl(buttonUrlPath);
  };

  useEffect(() => {
    setIsSelected(location.pathname === buttonUrlPath || location.pathname.startsWith("/s/market"));
  }, [location.pathname]);

  return (
    <a
      aria-label={tabLabel}
      tabIndex={0}
      onKeyDown={(e) => e.key === "Enter" && handleClick(e)}
      onClick={handleClick}
      href={buttonUrlPath}
      className={classNames(styles.itemButton, isSelected && styles.itemSelected)}
    >
      <div className={styles.itemButtonIconContainer}>
        <BrowseMarketsButtonIcon />
      </div>
      <FadeContainerComponent in={isExpanded}>
        <div className={styles.itemButtonTextContainer}>
          <TypographyComponent color={"stone"} rootClassName={styles.itemButtonText} boldness={"medium"} styling={"p2"}>
            {tabLabel}
          </TypographyComponent>
        </div>
      </FadeContainerComponent>
    </a>
  );
};

export default BrowseMarketsButtonComponent;
