import { useTranslation } from "react-i18next";
import { TypographyComponent } from "gx-npm-ui";
import VisitGartnerButtonIcon from "./visit-gartner-button.icon";
import FadeContainerComponent from "../../ui/fade-container.component";
import styles from "./visit-gartner-button.styles.module.scss";
import React from "react";

type VisitGartnerButtonComponentProps = {
  isExpanded?: boolean;
};

const VisitGartnerButtonComponent = ({ isExpanded = false }: VisitGartnerButtonComponentProps) => {
  const { t } = useTranslation();
  const buttonUrlPath = "https://www.gartner.com";
  const tabLabel = t("Visit Gartner.com");

  return (
    <FadeContainerComponent in={isExpanded}>
      <a
        aria-label={tabLabel}
        tabIndex={0}
        href={buttonUrlPath}
        target="_blank"
        rel="noreferrer"
        className={styles.itemButton}
      >
        <div className={styles.itemButtonIconContainer}>
          <VisitGartnerButtonIcon />
        </div>
        <div className={styles.itemButtonTextContainer}>
          <TypographyComponent color={"stone"} boldness={"medium"} rootClassName={styles.itemButtonText} styling={"p2"}>
            {tabLabel}
          </TypographyComponent>
        </div>
      </a>
    </FadeContainerComponent>
  );
};

export default VisitGartnerButtonComponent;
