import classNames from "classnames";
import { TypographyComponent } from "gx-npm-ui";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { navigateToUrl } from "single-spa";
import FadeContainerComponent from "../../ui/fade-container.component";
import styles from "../home-button/home-button.styles.module.scss";
import MyEvalsButtonIcon from "./my-evals-button.icon";

type MyEvalsButtonComponentProps = {
  isExpanded?: boolean;
};

const MyEvalsButtonComponent = ({ isExpanded = false }: MyEvalsButtonComponentProps) => {
  const { t } = useTranslation();
  const [isSelected, setIsSelected] = useState(false);
  const location = useLocation();
  const buttonUrlPath = "/s/evaluations";
  const tabLabel = t("My evaluations");
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement> | React.KeyboardEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    navigateToUrl(buttonUrlPath);
  };

  useEffect(() => {
    setIsSelected(location.pathname === buttonUrlPath);
  }, [location.pathname]);

  return (
    <a
      aria-label={tabLabel}
      tabIndex={0}
      onKeyDown={(e) => e.key === "Enter" && handleClick(e)}
      onClick={handleClick}
      href={buttonUrlPath}
      className={classNames(styles.itemButton, isSelected && styles.itemSelected)}
    >
      <div className={styles.itemButtonIconContainer}>
        <MyEvalsButtonIcon />
      </div>
      <FadeContainerComponent in={isExpanded}>
        <div className={styles.itemButtonTextContainer}>
          <TypographyComponent rootClassName={styles.itemButtonText} color={"stone"} boldness={"medium"} styling={"p2"}>
            {tabLabel}
          </TypographyComponent>
        </div>
      </FadeContainerComponent>
    </a>
  );
};

export default MyEvalsButtonComponent;
